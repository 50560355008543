import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Box, Fab, IconButton, Snackbar, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import authHook from '@hooks/useAuth'
import dialogHook from '@hooks/useDialog'
import errorHandlerHook from '@hooks/useErrorHandler'
import featuresHook from '@hooks/useFeatures'
import templateHook from '@hooks/useTemplate'

import ChatbotService from '@services/chatbot'

import { BetaTag } from '@components/BetaTag'

import { COSMO_AVATAR } from '@utils/images'

import {
  fabCosmoIconSx,
  fabCosmoSubTitleSx,
  fabCosmoTitleSx,
  fabSx,
  snackBarMessageCloseButtonSx,
  snackBarMessageContainerSx,
  snackBarMessageSubtitleSx,
  snackBarMessageSx,
  snackBarMessageTextContainerSx,
  snackBarMessageTitleSx,
} from './styles'

import { ChatbotDrawer } from './components/ChatbotDrawer'

const Chatbot = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { buyer, refreshBuyerProfile } = authHook.useAuth()
  const { availableFeatures, isLoadingAvailableFeatures } =
    featuresHook.useFeatures()

  const [isBuyerFirstInteraction, setIsBuyerFirstInteraction] = useState(false)

  const hasBuyerInteracted = buyer?.chatbotDetails?.interacted ?? false
  const isBuyerChatbotFeatureEnabled = availableFeatures?.buyerChatbot ?? false

  const chatbotDialog = dialogHook.useDialog()

  useEffect(() => {
    const disableCosmoOnboarding = searchParams.get('disable-cosmo-onboarding')
    setIsBuyerFirstInteraction(
      !hasBuyerInteracted &&
        isBuyerChatbotFeatureEnabled &&
        !disableCosmoOnboarding
    )
  }, [hasBuyerInteracted, isBuyerChatbotFeatureEnabled, searchParams])

  const [isMessageOpen, setIsMessageOpen] = useState(false)

  const { handleError } = errorHandlerHook.useErrorHandler()

  const isChatbotDrawerOpen = chatbotDialog.isOpen || isBuyerFirstInteraction

  const handleOnCloseFirstInteraction = async () => {
    try {
      await ChatbotService.createBuyerChatbotInteraction()

      await refreshBuyerProfile()
      setIsBuyerFirstInteraction(false)
      setIsMessageOpen(true)
      chatbotDialog.close()
    } catch (error) {
      handleError({ exception: error })
    }
  }

  const handleOnFabClickButton = () => {
    if (isMessageOpen) {
      setIsMessageOpen(false)
    }
    chatbotDialog.open()
  }

  const { bottomNavHeight } = templateHook.useTemplate()

  if (isLoadingAvailableFeatures) {
    return null
  }

  return (
    <>
      <Snackbar
        open={isMessageOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        data-testid="chatbot-message"
        sx={snackBarMessageSx}
      >
        <Box sx={snackBarMessageContainerSx}>
          <IconButton
            onClick={() => setIsMessageOpen(false)}
            aria-label="Dismiss message"
            data-testid="chatbot-close-message-button"
            sx={snackBarMessageCloseButtonSx}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={snackBarMessageTextContainerSx}>
            <Typography variant="subtitle1" sx={snackBarMessageTitleSx}>
              {t("Hey, it's Cosmo")}
            </Typography>
            <BetaTag />
          </Box>

          <Typography variant="body1" sx={snackBarMessageSubtitleSx}>
            {t(
              `Hey {{name}}! I'm always here in this corner to help you streamline your process and find the best-in-class match.`,
              {
                name: buyer?.firstName,
              }
            )}
          </Typography>
        </Box>
      </Snackbar>

      <ChatbotDrawer
        isOpen={isChatbotDrawerOpen}
        onClose={chatbotDialog.close}
        isFirstInteraction={isBuyerFirstInteraction}
        onCloseFirstInteraction={handleOnCloseFirstInteraction}
      />

      {!isChatbotDrawerOpen && (
        <Fab
          data-testid="chatbot-fab"
          color="primary"
          onClick={handleOnFabClickButton}
          sx={fabSx({ isMessageOpen, bottomNavHeight })}
        >
          {!isMessageOpen && (
            <Box>
              <Typography variant="h6" sx={fabCosmoTitleSx}>
                {t('Ask {{name}}', { name: 'Cosmo' })}
              </Typography>
              <Typography variant="body2" sx={fabCosmoSubTitleSx}>
                {t('Your AI Assistant')}
              </Typography>
            </Box>
          )}

          <Box
            alt={t('Cosmo Chatbot Avatar')}
            loading="lazy"
            component="img"
            src={COSMO_AVATAR}
            sx={fabCosmoIconSx}
          />
        </Fab>
      )}
    </>
  )
}

export { Chatbot }
