import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  flex: 1,
}

export const contentSx: SxProps<Theme> = {
  display: 'flex',
  maxHeight: '70vh',
}

export const chatContentSx: SxProps<Theme> = {
  padding: '2rem',
  flex: 1,
  overflow: 'auto',
}

export const formSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  height: '100%',
}

export const conversationSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem',
  flex: 1,
  maxHeight: '100%',
  overflow: 'auto',
}

export const titleSx: SxProps<Theme> = {
  fontWeight: 'bold ',
}

export const userTitleSx: SxProps<Theme> = {
  fontWeight: 'bold',
}

export const fabSx = ({
  isMessageOpen,
  bottomNavHeight,
}: {
  isMessageOpen: boolean
  bottomNavHeight: number
}): SxProps<Theme> => {
  const totalMobileSpacing = bottomNavHeight || 0

  return {
    zIndex: {
      xs: 1050,
      md: 1400,
    },
    position: 'fixed',
    bottom: {
      xs: `${totalMobileSpacing + 8}px`,
      lg: '3.2rem',
    },
    right: {
      xs: '1.2rem',
      md: '2.8rem',
    },
    width: isMessageOpen ? '6.4rem' : '20.2rem',
    maxHeight: '6.4rem !important',
    height: '6.4rem !important',
    background: (theme) => theme.palette.background.paper,
    display: 'flex',
    gap: '0.9rem',
    borderRadius: '11rem',
    padding: isMessageOpen ? '0.8rem' : '0.8rem 1.2rem 0.8rem 2rem',
    boxShadow:
      '0rem 0.8rem 4.2rem 0.7rem rgba(0, 0, 0, 0.12), 0rem 2.2rem 3.5rem 0.3rem rgba(0, 0, 0, 0.14), 0rem 1rem 1.4rem -0.6rem rgba(0, 0, 0, 0.20)',
    // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
    '&:hover': {
      background: (theme) => theme.palette.background.paper,
    },
  }
}

export const snackBarMessageSx: SxProps<Theme> = {
  padding: '1.6rem',
  background: 'white',
  width: '32rem',
  height: 'auto',
  display: 'flex',
  zIndex: 1200,
  flexDirection: 'column',
  borderRadius: '0.8rem',
  alignItems: 'start',
  boxShadow:
    '0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.20);',
  right: {
    xs: '1.2rem!important',
    md: '10rem!important',
  },
  bottom: {
    xs: '8.8rem!important',
    md: '3.2rem!important',
  },
}

export const snackBarMessageContainerSx: SxProps<Theme> = {
  position: 'relative',
}

export const snackBarMessageCloseButtonSx: SxProps<Theme> = {
  position: 'absolute',
  top: '-0.8rem',
  right: '-0.8rem',
}

export const snackBarMessageTextContainerSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: '1.6rem',
}

export const snackBarMessageTitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.primary,
}

export const snackBarMessageSubtitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const sendButtonSx = {
  alignSelf: 'end',
}

export const conversationListSx: SxProps<Theme> = {
  width: '25rem',
  borderRight: '0.1rem solid grey',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  maxHeight: '100%',
  overflow: 'auto',
}

export const fabCosmoIconSx: SxProps<Theme> = {
  width: '4.8rem',
  height: '4rem',
}

export const fabCosmoTitleSx: SxProps<Theme> = {
  textTransform: 'initial',
  color: (theme) => theme.palette.text.primary,
}
export const fabCosmoSubTitleSx: SxProps<Theme> = {
  textTransform: 'initial',
  color: (theme) => theme.palette.text.secondary,
}
