import { SxProps, Theme } from '@mui/material'

export const HOVER_BOX_SHADOW_DEFAULT = '0 0.5rem .627rem rgba(0, 0, 0, 0.34)'
export const HOVER_TRANSITION_DEFAULT = 'all 0.3s ease-out'

const oneLineEllipsis: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const scaleUpTransition: SxProps<Theme> = {
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.04)',
  },
}

const lineClamp = ({ lines }: { lines: number }): SxProps<Theme> => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
  }
}

export { lineClamp, oneLineEllipsis, scaleUpTransition }
