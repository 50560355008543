import { SxProps, Theme } from '@mui/material'

import { AvatarSize } from './types'

const SIZE = {
  [AvatarSize.MICRO]: {
    width: '2.4rem',
    height: '2.4rem',
  },
  [AvatarSize.SMALL]: {
    width: '4rem',
    height: '4rem',
  },
  [AvatarSize.MEDIUM]: {
    width: '4.8rem',
    height: '4.8rem',
  },
  [AvatarSize.LARGE]: {
    width: '6.4rem',
    height: '6.4rem',
  },
}

export const avatarStyleSx = ({
  size,
}: {
  size?: AvatarSize
}): SxProps<Theme> => {
  return {
    background: (theme) => theme.palette.background.paper,
    ...(size ? SIZE[size] : { width: '5.4rem', height: '5.4rem' }),
  }
}

export const defaultAvatarStyleSx = ({
  size,
}: {
  size?: AvatarSize
}): SxProps<Theme> => {
  return {
    ...(size ? SIZE[size] : { width: '5.4rem', height: '5.4rem' }),
    border: (theme) => `0.2rem solid ${theme.palette.background.paper}`,
    color: (theme) => theme.palette.background.default,
  }
}
