import { SxProps, Theme } from '@mui/material'

export const dialogSx: SxProps<Theme> = {
  zIndex: 1_700,
}

export const dialogPaperSx: SxProps<Theme> = {
  margin: 0,
  borderRadius: {
    xs: 0,
    lg: '0.8rem',
  },
  width: '100%',
  maxWidth: {
    lg: '113.9rem',
  },
  minHeight: {
    xs: '100%',
    lg: 'auto',
  },
  height: {
    lg: '87.2rem',
  },
  maxHeight: {
    lg: '90%',
  },
}

export const circularProgressSx: SxProps<Theme> = {
  alignSelf: 'center',
  display: 'flex',
  flex: 1,
}

export const contentSx: SxProps<Theme> = { flex: 1, overflow: 'hidden' }

export const chatWrapperSx: SxProps<Theme> = {
  flex: 1,
}

export const chatContentSx: SxProps<Theme> = {
  flex: 1,
  overflow: 'hidden',
}
