export const LOCAL_STORAGE_EVENT_LISTENER_KEY = 'storage'

export const LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY = '@tlg:chat_visitor_info'
export const LOCAL_STORAGE_USER_SIGNOUT_EVENT_KEY = '@tlg:user_signout_event'
export const LOCAL_STORAGE_TAB_FOCUSED = '@tlg:has_tab_focused'
export const LOCAL_STORAGE_USER_TYPE_KEY = '@tlg:local_storage.user_type_key'
export const LOCAL_STORAGE_LAST_SCROLL_POSITION = '@tlg:last_scroll_position'
export const LOCAL_STORAGE_INITIAL_COMPANY_QUESTIONS_ANSWERED =
  '@tlg:initial_company_questions_answered'
export const LOCAL_STORAGE_NEW_FEATURES = '@tlg:new_features'
