import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import authHook from '@hooks/useAuth'
import featuresHook from '@hooks/useFeatures'
import navigationHook from '@hooks/useNavigation'

import { withStreamChatManagedConnector } from '@HOCs/withStreamChatManagedConnector'

import { StreamChatAnonymousConnection } from '@components/StreamChatAnonymousConnection'
import { StreamChatPrivateConnection } from '@components/StreamChatPrivateConnection'

const BuyerOrVisitorSolutionView = lazy(() =>
  import('@pages/SolutionView').then((module) => {
    return {
      default: module.SolutionView,
    }
  })
)

const BuyerOrVisitorPublicSolution_v2 = lazy(() =>
  import('@pages/PublicSolution_v2').then((module) => {
    return {
      default: module.PublicSolution_v2,
    }
  })
)

const LandingPageTemplateLazy = lazy(() =>
  import('@pages/LandingPage/Template').then((module) => {
    return {
      default: module.LandingPageTemplate,
    }
  })
)

const BuyerSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatPrivateConnection,
  { authenticated: true }
)

const BuyerPublicSolution_V2_ViewWithChatConnection =
  withStreamChatManagedConnector(
    BuyerOrVisitorPublicSolution_v2,
    StreamChatPrivateConnection,
    { authenticated: true }
  )

const VisitorSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatAnonymousConnection,
  { authenticated: false }
)

const VisitorSolution_V2_ViewWithChatConnection =
  withStreamChatManagedConnector(
    BuyerOrVisitorPublicSolution_v2,
    StreamChatAnonymousConnection,
    { authenticated: false }
  )

const SolutionViewMiddleware = () => {
  const { isFetchingUserData, isBuyer } = authHook.useAuth()
  const { availableFeatures, isLoadingAvailableFeatures } =
    featuresHook.useFeatures()
  const location = useLocation()
  const { solutionIdOrSlug } = useParams()
  const { navigateTo, changeUrlPath } = navigationHook.useNavigation()

  const removeBuyerFromSolutionPath = useCallback(() => {
    if (location.pathname.startsWith('/buyer/solutions/')) {
      const newPath = `/solutions/${solutionIdOrSlug}`
      changeUrlPath({ newPath })
    }
  }, [changeUrlPath, location.pathname, solutionIdOrSlug])

  useEffect(() => {
    if (isBuyer() && !location.pathname.startsWith('/buyer/solutions/')) {
      const newPath = `/buyer${location.pathname}`

      navigateTo({
        path: newPath,
        replace: true,
      })

      return
    }

    removeBuyerFromSolutionPath()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isBuyer, navigateTo, removeBuyerFromSolutionPath])

  const ComponentToRender = useMemo(() => {
    if (isBuyer()) {
      if (availableFeatures.solutionV2) {
        return <BuyerPublicSolution_V2_ViewWithChatConnection />
      }

      return <BuyerSolutionViewWithChatConnection />
    }

    if (availableFeatures.solutionV2) {
      return (
        <LandingPageTemplateLazy>
          <VisitorSolution_V2_ViewWithChatConnection />
        </LandingPageTemplateLazy>
      )
    }

    return <VisitorSolutionViewWithChatConnection />
  }, [availableFeatures.solutionV2, isBuyer])

  if (isFetchingUserData || isLoadingAvailableFeatures) {
    return <></>
  }

  return <Suspense fallback={<></>}>{ComponentToRender}</Suspense>
}

export { SolutionViewMiddleware }
