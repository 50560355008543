import { SxProps, Theme } from '@mui/material'

export const COPYRIGHT_FOOTER_HEIGHT = '4.4rem'

export const wrapperSx: SxProps<Theme> = {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '1.2rem',
  backgroundColor: '#E8EDF5',
}

export const containerSx = (bottomNavHeight: number): SxProps<Theme> => {
  return {
    position: 'sticky',
    bottom: {
      xs: `${bottomNavHeight}px`,
      lg: 0,
    },
    left: 0,
    right: 0,
    bgcolor: 'background.paper',
  }
}
