import { useTranslation } from 'react-i18next'

import { Box, Button, Paper, Typography } from '@mui/material'

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'

import authHook from '@hooks/useAuth'
import responsiveHook from '@hooks/useResponsive'

import type { IImpersonatedUser } from '@utils/types'

import {
  backButtonSx,
  impersonationBarSx,
  impersonationIconSx,
  impersonationTextSx,
} from './styles'

export const IMPERSONATION_BAR_HEIGHT = '8rem'

interface IImpersonationBarProps {
  impersonation: IImpersonatedUser
}

const ImpersonationBar = ({ impersonation }: IImpersonationBarProps) => {
  const { t } = useTranslation()
  const { impersonate } = authHook.useAuth()

  const { isMobile } = responsiveHook.useResponsive()

  const handleOnClose = async () => {
    await impersonate()
    window.close()
  }

  return (
    <Paper sx={impersonationBarSx}>
      <Typography sx={impersonationTextSx}>
        {!isMobile && <KeyOutlinedIcon sx={impersonationIconSx} />}
        {`${t('Admin Account')} - ${impersonation.username}`}
      </Typography>
      <Box>
        <Button
          aria-label={t('Back to admin panel')}
          variant="contained"
          size="medium"
          startIcon={<CheckOutlinedIcon />}
          sx={backButtonSx}
          onClick={handleOnClose}
        >
          {!isMobile ? t('Back to Admin Panel') : t('Back')}
        </Button>
      </Box>
    </Paper>
  )
}

export { ImpersonationBar }
