import { useState } from 'react'

import WindowEventUtils from '@utils/window-events'

export const useLocalStorage = <T = any>(key: string, initialValue: T) => {
  const getValue = (): T => {
    if (typeof window === 'undefined' || !window.localStorage) {
      return initialValue
    }

    const item = window.localStorage.getItem(key)
    return (item ? JSON.parse(item) : initialValue) as T
  }

  const setValue = (
    value: T,
    {
      customWindowEvent,
    }: {
      customWindowEvent?: keyof WindowEventMap
    } = {}
  ) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value

    setStoredValue(valueToStore)

    window.localStorage.setItem(key, JSON.stringify(valueToStore))

    if (customWindowEvent) {
      WindowEventUtils.dispatch({
        event: customWindowEvent,
        value: valueToStore,
      })
    }
  }

  const [storedValue, setStoredValue] = useState<T>(getValue)

  return {
    storedValue,
    getValue,
    setValue,
  }
}

export default {
  useLocalStorage,
}
