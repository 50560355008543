import { createContext, useCallback, useEffect, useState } from 'react'

import authHook from '@hooks/useAuth'
import errorHandlerHook from '@hooks/useErrorHandler'

import GlobalSettingService from '@services/global-setting'

import { type IFeatureAvailability } from '@utils/types'

export type FeaturesContextState = {
  availableFeatures: IFeatureAvailability
  isLoadingAvailableFeatures: boolean
}

export const FeatureAvailabilityContext = createContext<FeaturesContextState>(
  {} as FeaturesContextState
)

interface FeatureAvailabilityProviderProps {
  children: React.ReactNode
}

const FeatureAvailabilityProvider = ({
  children,
}: FeatureAvailabilityProviderProps) => {
  const { isFetchingUserData, isAdmin, isBuyer, isVendor } = authHook.useAuth()
  const { handleError } = errorHandlerHook.useErrorHandler()

  const [isLoadingAvailableFeatures, setIsLoadingAvailableFeatures] =
    useState(true)

  const [availableFeatures, setAvailableFeatures] =
    useState<IFeatureAvailability>({
      marketTrends: false,
      buyerChatbot: false,
      solutionV2: false,
    })

  const fetchMyUserFeaturesAvailabilities = useCallback(async () => {
    try {
      const response =
        await GlobalSettingService.getMyUserFeatureAvailabilities()

      setAvailableFeatures(response)
    } catch (err) {
      handleError({ exception: err })
    }

    setIsLoadingAvailableFeatures(false)
  }, [handleError])

  const fetchIsSolutionV2EnabledForVisitor = useCallback(async () => {
    try {
      const response = await GlobalSettingService.getIsSolutionsEnabled()

      setAvailableFeatures((v) => {
        return {
          ...v,
          solutionV2: response,
        }
      })
    } catch (err) {
      handleError({ exception: err })
    }

    setIsLoadingAvailableFeatures(false)
  }, [handleError])

  useEffect(() => {
    if (!isLoadingAvailableFeatures || isFetchingUserData) {
      return
    }

    // TODO delete the isAdmin() once we delete all the Solution V1 stuff
    if (isAdmin() || isBuyer() || isVendor()) {
      fetchMyUserFeaturesAvailabilities()
      return
    }

    fetchIsSolutionV2EnabledForVisitor()
  }, [
    isFetchingUserData,
    isLoadingAvailableFeatures,
    fetchMyUserFeaturesAvailabilities,
    fetchIsSolutionV2EnabledForVisitor,
    isAdmin,
    isBuyer,
    isVendor,
  ])

  return (
    <FeatureAvailabilityContext.Provider
      value={{
        availableFeatures,
        isLoadingAvailableFeatures,
      }}
    >
      {children}
    </FeatureAvailabilityContext.Provider>
  )
}

export { FeatureAvailabilityProvider }
