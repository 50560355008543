import { SetOptional } from 'type-fest'

import { IQueryFilters, IQueryFiltersStripe, UserType } from '@utils/types'

export const URL_REGEX =
  /((https:\/\/www\.|https:\/\/)+[a-zA-Z-]{2,}(\.[a-zA-Z-]{2,})(\.[a-zA-Z-]{2,})?)|(https:\/\/www\.|https:\/\/)+[a-zA-Z0-9-]{2,}\.[a-zA-Z0-9-]{2,}\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?/g

export const getDomainWithoutSubdomain = (href: string) => {
  const url = new URL(href)
  const hostnameParts = url.hostname.split('.')

  if (hostnameParts.length > 2) {
    return hostnameParts.slice(-2).join('.')
  } else {
    return url.hostname
  }
}

export const getDomainUrl = () => {
  const protocol = window.location.protocol
  const host = window.location.host
  return `${protocol}//${host}`
}

export const buildQueryString = (
  queryFilters?: SetOptional<IQueryFilters, 'page' | 'limit'>
) => {
  if (!queryFilters) {
    return undefined
  }

  let query: { [key: string]: string } = {}

  const { filters, page, limit, negativeOffset, sortModel } = queryFilters

  if (!!filters) {
    const filtersQuery = filters?.reduce((acc, filter) => {
      const field = filter.field
      let value = filter.value

      if (value instanceof Date) {
        value = value.toISOString()
      }

      return {
        ...acc,
        [field]: value,
      }
    }, {})

    query = { ...query, ...filtersQuery }
  }

  if (!!page) {
    query = { ...query, page: String(page) }
  }

  if (!!limit) {
    query = { ...query, limit: String(limit) }
  }

  if (!!negativeOffset) {
    query = { ...query, negativeOffset: String(negativeOffset) }
  }

  if (sortModel?.sort) {
    query = { ...query, order: sortModel.field, sort: sortModel.sort }
  }

  return query
}

export const buildQueryStringStripe = (
  queryFilters?: SetOptional<IQueryFiltersStripe, 'nextPageToken' | 'limit'>
) => {
  if (!queryFilters) {
    return undefined
  }

  let query: { [key: string]: string } = {}

  const { filters, nextPageToken, limit } = queryFilters

  if (!!filters) {
    const filtersQuery = filters?.reduce((acc, filter) => {
      const field = filter.field
      let value = filter.value

      if (value instanceof Date) {
        value = value.toISOString()
      }

      return {
        ...acc,
        [field]: value,
      }
    }, {})

    query = { ...query, ...filtersQuery }
  }

  if (!!nextPageToken) {
    query = { ...query, nextPageToken: String(nextPageToken) }
  }

  if (!!limit) {
    query = { ...query, limit: String(limit) }
  }

  return query
}

const changeQueryString = ({
  param,
  value,
  push,
}: {
  param: string
  value?: string
  push: boolean
}) => {
  const { pathname } = window.location
  const url = !!value ? `${pathname}?${param}=${value}` : pathname

  if (push) {
    window.history.pushState('', '', url)
    return
  }

  window.history.replaceState('', '', url)
}

const changeUrlPath = ({
  newPath,
  push = false,
}: {
  newPath: string
  push?: boolean
}) => {
  if (push) {
    window.history.pushState('', '', newPath)
    return
  }
  window.history.replaceState('', '', newPath)
}

const getUserTypeFromHash = (hash: string) => {
  if (!hash) {
    return UserType.BUYER
  }

  const userTypes = [UserType.BUYER, UserType.VENDOR] as string[]

  const userType = hash.replace('#', '')

  if (!userTypes.includes(userType)) {
    return UserType.BUYER
  }

  return userType as UserType
}

const changeHash = (newHash: string) => {
  window.history.replaceState('', '', '#' + newHash)
}

const clearHash = () => {
  changeQueryString({
    param: '',
    value: '',
    push: false,
  })
}

export const openUrlInNewTab = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  a.click()
}

const exports = {
  buildQueryString,
  buildQueryStringStripe,
  getDomainUrl,
  changeQueryString,
  changeUrlPath,
  getUserTypeFromHash,
  changeHash,
  clearHash,
  openUrlInNewTab,
}

export default exports
