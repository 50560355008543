import { Dispatch, FC, SetStateAction, createContext, useState } from 'react'

import { SxProps, Theme } from '@mui/material'

export type TemplateContextState = {
  footerCopyrightSx?: SxProps<Theme>
  bottomNavHeight: number
  footerHeight: number
  chatbotSpaceVisible: boolean
  setFooterCopyrightSx: Dispatch<SetStateAction<SxProps<Theme> | undefined>>
  setBottomNavHeight: (height: number) => void
  setFooterHeight: (height: number) => void
  setChatbotSpaceVisible: (visible: boolean) => void
}

export const TemplateContext = createContext<TemplateContextState>(
  {} as TemplateContextState
)

const TemplateProvider: FC = ({ children }) => {
  const [footerCopyrightSx, setFooterCopyrightSx] = useState<SxProps<Theme>>()
  const [bottomNavHeight, setBottomNavHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const [chatbotSpaceVisible, setChatbotSpaceVisible] = useState(true)

  return (
    <TemplateContext.Provider
      value={{
        footerCopyrightSx,
        bottomNavHeight,
        footerHeight,
        chatbotSpaceVisible,
        setFooterCopyrightSx,
        setBottomNavHeight,
        setFooterHeight,
        setChatbotSpaceVisible,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}

export { TemplateProvider }
