import { useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Stack, SxProps, Theme } from '@mui/material'

import templateHook from '@hooks/useTemplate'

import { TLGTypography } from '@components/TLGTypography'

import { containerSx, wrapperSx } from './styles'

interface FooterCopyrightProps {
  containerSx?: SxProps<Theme>
  wrapperSx?: SxProps<Theme>
}

const FooterCopyright = ({
  containerSx: containerSxOverride,
  wrapperSx: wrapperSxOverride,
}: FooterCopyrightProps = {}) => {
  const { t } = useTranslation()
  const { bottomNavHeight } = templateHook.useTemplate()
  const footerRef = useRef<HTMLDivElement>(null)

  const { setFooterHeight } = templateHook.useTemplate()

  const currentYear = new Date().getFullYear()
  const currentVersion = process.env.REACT_APP_VERSION

  useLayoutEffect(() => {
    if (!footerRef.current) {
      return
    }
    setFooterHeight(footerRef.current.offsetHeight)
  }, [setFooterHeight])

  return (
    <Box
      ref={footerRef}
      sx={
        {
          ...containerSx(bottomNavHeight),
          ...containerSxOverride,
        } as SxProps<Theme>
      }
    >
      <Stack
        component="footer"
        data-testid="footer-copyright"
        direction="row"
        sx={{ ...wrapperSx, ...wrapperSxOverride } as SxProps<Theme>}
      >
        <TLGTypography variant="body2">
          {t(
            'All rights reserved © {{currentYear}} The Launchpad - {{currentVersion}}',
            { currentYear, currentVersion }
          )}
        </TLGTypography>
      </Stack>
    </Box>
  )
}

export { FooterCopyright }
