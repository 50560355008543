const MM_DD_YYYY_REGEX = /^\d{2}\/\d{2}\/\d{4}$/

const capitalizeWords = (value?: string) => {
  if (!value) {
    return ''
  }

  return value.replace(/(?:^|\s)\S/g, function (v: string) {
    return v.toUpperCase()
  })
}

const toCapitalCase = (value?: string) => {
  if (!value) {
    return ''
  }

  return value.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase()
  })
}

const toCamelCase = (
  word: string,
  options?: {
    splitter?: string | RegExp
  }
) => {
  const words = word.split(options?.splitter ?? ' ')

  return words
    .map((word, index) => {
      if (index === 0) {
        return word[0].toLowerCase() + word.slice(1)
      }

      return word[0].toUpperCase() + word.slice(1)
    })
    .join('')
}

const toKebabCase = (value: string) =>
  value.trim().replace(/ +/g, '-').toLowerCase()

const singularOrPlural = ({
  singularWord,
  pluralWord,
  count,
  isPlural,
}: {
  singularWord: string
  pluralWord: string
} & (
  | {
      count?: number
      isPlural?: never
    }
  | {
      count?: never
      isPlural?: boolean
    }
)) => (!isPlural && (count ?? 0) === 1 ? singularWord : pluralWord)

const getPast = ({ word }: { word?: string }) => {
  if (!word) {
    return ''
  }
  return word.replace(/e$/, '') + 'ed'
}

const truncateWithEllipsis = ({
  text,
  maxLength,
}: {
  text?: string
  maxLength: number
}) => {
  if (!text || text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength - 3) + '...'
}

export default {
  MM_DD_YYYY_REGEX,
  capitalizeWords,
  toKebabCase,
  singularOrPlural,
  getPast,
  toCapitalCase,
  toCamelCase,
  truncateWithEllipsis,
}
