import { SxProps, Theme } from '@mui/material'

export const impersonationBarSx: SxProps<Theme> = {
  height: '8rem',
  width: '100%',
  marginBottom: '0.2rem',
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 2.4rem',
  position: 'sticky',
  margin: 0,
  top: 0,
  zIndex: 1_700,
}

export const impersonationTextSx: SxProps<Theme> = {
  display: { xs: 'block', md: 'flex' },
  fontSize: { xs: '1.6rem', lg: '2.4rem' },
  lineHeight: { xs: '2.4rem', lg: '3.2rem' },
  alignItems: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

export const impersonationIconSx: SxProps<Theme> = {
  width: '3.2rem',
  height: '3.2rem',
  color: 'text.secondary',
  mr: '2.4rem',
}

export const backButtonSx: SxProps<Theme> = {
  marginLeft: '2.4rem',
  display: 'flex',
}
